export default function initBsrAnchorNavigation() {
    const elements = document.querySelectorAll('.bsr-anchor-navigation');
    const buttonHiddenClassname = 'bsr-anchor-navigation__scrollButton--hidden';
    const linkClassname = 'bsr-anchor-navigation__link';

    elements.forEach((element) => {
        const nextButton = element.querySelector('.bsr-anchor-navigation__scrollButton--next');
        const prevButton = element.querySelector('.bsr-anchor-navigation__scrollButton--prev');

        const scrollContainer = element.querySelector('.bsr-anchor-navigation__scrollContainer');

        nextButton.addEventListener('click', () => {
            scrollContainer.scrollBy({
                top: 0,
                left: 100,
                behavior: "smooth",
            });
        });

        prevButton.addEventListener('click', () => {
            scrollContainer.scrollBy({
                top: 0,
                left: -100,
                behavior: "smooth",
            });
        });


        const isScrollable = () => scrollContainer.scrollWidth > scrollContainer.offsetWidth;
        const canScrollLeft = () => scrollContainer.scrollLeft > 0;
        const canScrollRight = () => scrollContainer.scrollLeft < (scrollContainer.scrollWidth - scrollContainer.offsetWidth);
        
        const debounce = (func, delay) => {
            let timeout;
            const debounced = (...args) => new Promise((resolve) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => resolve(func(...args), delay));
            })
            return debounced;
        }
        
        const handleScroll = () => {
            if(canScrollLeft()) {
                prevButton.classList.remove(buttonHiddenClassname);
            } else {
                prevButton.classList.add(buttonHiddenClassname);
            }
            if(canScrollRight()) {
                nextButton.classList.remove(buttonHiddenClassname);
            } else {
                nextButton.classList.add(buttonHiddenClassname);
            }
        };

        const handleResize = () => {
            if(!isScrollable()) {
                prevButton.classList.add(buttonHiddenClassname);
                nextButton.classList.add(buttonHiddenClassname);
            } else {
                handleScroll();
            }
        };

        handleResize();
        scrollContainer.addEventListener('scroll', debounce(handleScroll, 100));
        window.addEventListener('resize', debounce(handleResize, 100));
        
        const links = Array.from(element.querySelectorAll(`.${linkClassname}`) || []);

        const blockLinkMapping = links.reduce((acc, currentLinkElement) => {
            const referencedId = currentLinkElement.getAttribute('href');           
            const referencedElement = document.querySelector(referencedId);

            return {
                ...acc,
                [referencedId]: {
                    link: currentLinkElement,
                    block: referencedElement
                }
            }
        }, {});

        const onIntersection = (linkElement, entries, observer) => {
            if(entries[0].isIntersecting) {
                linkElement.classList.add(`${linkClassname}--active`);
                linkElement.scrollIntoView({
                    inline: 'start',
                    block: 'nearest'
                });
            } else {
                linkElement.classList.remove(`${linkClassname}--active`);
            }
        }

        Object.values(blockLinkMapping).forEach((entry) => {
            if(!entry.block) {
                return;
            }

            const observer = new IntersectionObserver(
                
                onIntersection.bind(this, entry.link),
                {
                    threshold: 0.75,
                    rootMargin: '0px 0px 5% 0px',
                }
            );
            observer.observe(entry.block);
            return observer;
        });

    });
}